<template>
  <video-tab-styles>
    <div class="d-flex">
      <div class="mr-4">
        <div
          class="mb-2"
          style="width: 300px; min-width: 300px;"
        >
          <h5>Preview</h5>
          <video-player
            ref="player"
            :video-type="videoType"
            :lesson="lesson"
          />
        </div>

        <div
          v-if="editable"
          class="text-center"
        >
          <upload-video
            ref="uploadVideo"
            :lesson="lesson"
            :video-type="videoType"
            class="text-center mb-2"
            @lessonUpdated="() => $emit('lessonUpdated')"
          />

          <upload-video-image
            v-if="lesson.videos[videoType].video"
            ref="uploadImage"
            :lesson="lesson"
            :video-type="videoType"
            class="text-center mb-2"
            @lessonUpdated="() => $emit('lessonUpdated')"
          />
        </div>

        <hr class="my-2">

        <div>
          <h5>Subtitles</h5>
          <subtitles
            :lesson="lesson"
            :video-type="videoType"
            @lessonUpdated="() => $emit('lessonUpdated')"
            @subtitlesUpdated="() => $refs.player.subtitlesUpdated()"
          />
        </div>
      </div>
      <div class="w-100">

        <div class="mb-4 lesson-video-text-manager-container">
          <text-manager
            :lesson="lesson"
            :video-type="videoType"
            :show-story="showStory"
            @lessonUpdated="() => $emit('lessonUpdated')"
          />
        </div>
      </div>
    </div>
  </video-tab-styles>
</template>

<script>
import VideoPlayer from '@/views/Lesson/Item/Components/VideoTab/VideoPlayer.vue'
import UploadVideo from '@/views/Lesson/Item/Components/VideoTab/UploadVideo.vue'
import UploadVideoImage from '@/views/Lesson/Item/Components/VideoTab/UploadVideoImage.vue'
import Subtitles from '@/views/Lesson/Item/Components/VideoTab/Subtitles.vue'
import TextManager from '@/views/Lesson/Item/Components/VideoTab/TextManager/Index.vue'
import { VideoTabStyles } from '../../../styled'

export default {
  components: {
    VideoPlayer,
    UploadVideo,
    UploadVideoImage,
    Subtitles,
    TextManager,
    VideoTabStyles,
  },
  props: {
    lesson: {
      type: Object,
      required: true,
    },
    videoType: {
      type: String,
      required: true,
    },
    showStory: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    editable() {
      return !this.lesson.published_at
    },
  },
  methods: {
    videoUploading() {
      return this.$refs.uploadVideo && this.$refs.uploadVideo.uploading()
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
