<template>
  <story-dictionary-styles>
    <div
      :class="{
        'dictionary-new-highlight': dictionaryNewHighlight,
        'dictionary-used-once-highlight': dictionaryUsedOnceHighlight,
        'dictionary-used-twice-highlight': dictionaryUsedTwiceHighlight,
        'dictionary-used-more-highlight': dictionaryUsedMoreHighlight,
      }"
    >
      <div class="row">
        <div class="col-7 lesson-text-edit">
          <span
            v-for="val in storyTextWithDictionaryArray"
            :key="val.id"
            :data-text-pos-from="val.textPosFrom"
            :data-is-text-node="val.attachedDictionary === null ? 1 : 0"
            class="value text-item-node"
          >
            <template v-if="val.attachedDictionary !== null">
              <span
                :id="'text-' + val.id"
                class="dict-value"
                :class="{
                  'dictionary-new-highlight-color': dictionaryIsNew.includes(val.attachedDictionary.dictionary_item.id),
                  'dictionary-used-once-highlight-color': dictionaryIsUsedOnce.includes(val.attachedDictionary.dictionary_item.id),
                  'dictionary-used-twice-highlight-color': dictionaryIsUsedTwice.includes(val.attachedDictionary.dictionary_item.id),
                  'dictionary-used-more-highlight-color': dictionaryIsUsedMore.includes(val.attachedDictionary.dictionary_item.id),
                  'dictionary-focused-color': storyAttachedDictionaryFocusedIndex === val.attachedDictionaryIndex,
                }"
                @mouseover="storyAttachedDictionaryFocusedIndex = val.attachedDictionaryIndex"
                @mouseout="storyAttachedDictionaryFocusedIndex = null"
                v-text="val.text"
              />
              <b-popover
                :ref="'popover-' + val.id"
                :target="'text-' + val.id"
                triggers="hover"
                placement="top"
              >
                <div
                  class="lesson-text-edit__popover-dict-value cursor-pointer"
                  @click="() => showDictionarySelectorItemModal(val.attachedDictionary)"
                >
                  {{ val.attachedDictionary.translate }}
                  <div class="text-muted text-xs">
                    {{ attachedDictionaryPartOfSpeech(val.attachedDictionary) }}
                    {{ val.attachedDictionary.verb_time }}
                  </div>
                </div>
                <feather-icon
                  v-if="editable"
                  icon="LinkIcon"
                  size="12"
                  class="cursor-pointer position-absolute"
                  :style="{
                    'top': '5px',
                    'right': '5px',
                  }"
                  title="Detach"
                  @click="popoverDetachClick(val)"
                />
              </b-popover>
            </template>
            <template v-else>
              <span
                :id="'text-' + val.id"
                :class="{
                  selected: !!getSelectionStartsFromIndex(selection, val.textPosFrom),
                }"
                v-text="val.text"
              />
            </template>
          </span>
          <div
            v-if="changed"
            class="d-flex align-items-center mt-2"
          >
            <b-button
              variant="primary"
              :disabled="loading"
              class="mr-1"
              @click="save"
            >
              <feather-icon icon="SaveIcon" />
              Save
            </b-button>
            <b-button
              variant="outline-secondary"
              :disabled="loading"
              class="mr-1"
              @click="cancel"
            >
              Cancel
            </b-button>
          </div>
          <div
            v-if="storyTextWithDictionaryArray.length"
            class="d-flex align-items-center mt-2"
          >
            <span
              class="font-small-3 mr-2 cursor-pointer"
              :class="{
                'text-muted': !dictionaryNewHighlight,
                'dictionary-new-highlight-color': dictionaryNewHighlight,
              }"
              @click="dictionaryNewHighlightChange"
            >
              New: {{ dictionaryIsNew.length }}
            </span>
            <span
              class="font-small-3 mr-2 cursor-pointer"
              :class="{
                'text-muted': !dictionaryUsedOnceHighlight,
                'dictionary-used-once-highlight-color': dictionaryUsedOnceHighlight,
              }"
              @click="dictionaryUsedOnceHighlightChange"
            >
              <span class="dictionary-used-number">1</span> {{ dictionaryIsUsedOnce.length }}
            </span>
            <span
              class="font-small-3 mr-2 cursor-pointer"
              :class="{
                'text-muted': !dictionaryUsedTwiceHighlight,
                'dictionary-used-twice-highlight-color': dictionaryUsedTwiceHighlight,
              }"
              @click="dictionaryUsedTwiceHighlightChange"
            >
              <span class="dictionary-used-number">2</span> {{ dictionaryIsUsedTwice.length }}
            </span>
            <span
              class="font-small-3 mr-2 cursor-pointer"
              :class="{
                'text-muted': !dictionaryUsedMoreHighlight,
                'dictionary-used-more-highlight-color': dictionaryUsedMoreHighlight,
              }"
              @click="dictionaryUsedMoreHighlightChange"
            >
              <span class="dictionary-used-number">3+</span> {{ dictionaryIsUsedMore.length }}
            </span>
          </div>
        </div>
        <dictionary-selector
          ref="dictionarySelector"
          class="col-5"
          :class="{ 'border-left': selection.length }"
          :native-language="lesson.native_lang"
          :foreign-language="lesson.foreign_lang"
          @selected="dictionarySelector_onSelected"
          @closed="dictionarySelector_onClosed"
        />
        <dictionary-selector-item-modal
          ref="dictionarySelectorItemModal"
          @selected="dictionarySelector_onSelected"
          @closed="dictionarySelector_onClosed"
        />
      </div>
    </div>
  </story-dictionary-styles>
</template>

<script>
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'
import DictionarySelector from '@/views/Lesson/Item/Components/VideoTab/TextManager/StoryDictionary/DictionarySelector.vue'
import { BButton, BPopover } from 'bootstrap-vue'
import { uniqueId } from 'lodash/util'
import { uniqBy } from 'lodash/array'
import toast from '@/mixins/toast'
import textManager from '@/mixins/textManager'
import DictionarySelectorItemModal from '@/views/Lesson/Item/Components/VideoTab/TextManager/StoryDictionary/DictionarySelectorItemModal.vue'
import { StoryDictionaryStyles } from '../../../../../styled'

export default {
  components: {
    DictionarySelectorItemModal,
    DictionarySelector,
    BButton,
    BPopover,
    StoryDictionaryStyles,
  },
  mixins: [
    toast,
    textManager,
  ],
  props: {
    lesson: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    changed: false,
    loading: false,

    // selection
    selectionMultiple: false,
    selection: [],

    attachedDictionary: [],
    storyAttachedDictionaryFocusedIndex: null,

    // new / repeat
    previouslyUsedDictionaries: [],
    previouslyUsedDictionariesLoaded: false,
    dictionaryNewHighlight: false,
    dictionaryUsedOnceHighlight: false,
    dictionaryUsedTwiceHighlight: false,
    dictionaryUsedMoreHighlight: false,
    dictionaryIsNew: [],
    dictionaryIsUsedOnce: [],
    dictionaryIsUsedTwice: [],
    dictionaryIsUsedMore: [],
  }),
  computed: {
    editable() {
      return !this.lesson.published_at
    },
    dictionaryNewRepeatCountable() {
      return true
    },
    dictionaryStoryPosMultiSelectable() {
      return true
    },
    storyText() {
      return get(this.lesson, 'story.text', '')
    },
    storyTextWithDictionaryArray() {
      const { storyText } = this
      const textArray = []
      const textArrayPush = (text, textPosFrom, attachedDictionary = null, attachedDictionaryIndex = null) => {
        textArray.push({
          id: uniqueId(),
          text,
          textPosFrom,
          attachedDictionary,
          attachedDictionaryIndex,
        })
      }

      // storyText chars (1: without dictionary, 2: not selected)
      let tmpText = ''
      let tmpTextPosFrom = null
      const textArrayPushTmpText = () => {
        if (tmpText !== '') {
          textArrayPush(tmpText, tmpTextPosFrom)
          tmpText = ''
          tmpTextPosFrom = null
        }
      }

      // loop chars
      for (let charIndex = 0; charIndex < storyText.length; charIndex += 1) {
        // char is selected
        const charSelection = this.getSelectionStartsFromIndex(this.selection, charIndex)

        if (charSelection) {
          // push tmp text
          textArrayPushTmpText()
          // push selected text
          textArrayPush(
            storyText.substr(charSelection.from, charSelection.to - charSelection.from),
            charSelection.from,
          )
          // jump over indices
          charIndex = charSelection.to - 1
        } else {
          // search attachedDictionary
          let attachedDictionaryIndex = -1
          let attachedDictionaryPosIndex = null
          this.attachedDictionary.forEach((dictionaryItem, i) => {
            const selectionIndex = findIndex(dictionaryItem.story_pos, { from: charIndex })
            if (selectionIndex !== -1) {
              attachedDictionaryIndex = i
              attachedDictionaryPosIndex = selectionIndex
            }
          })
          const attachedDictionary = attachedDictionaryIndex !== -1 ? this.attachedDictionary[attachedDictionaryIndex] : null

          // // selection text inside attachedDictionary
          // const selectedInAttachedDict = attachedDictionary
          //   && charSelection
          //   && (
          //     (
          //       charSelection.from >= attachedDictionary.story_pos[attachedDictionaryPosIndex].from
          //         && charSelection.from < attachedDictionary.story_pos[attachedDictionaryPosIndex].to
          //     ) || (
          //       charSelection.to >= attachedDictionary.story_pos[attachedDictionaryPosIndex].from
          //         && charSelection.to < attachedDictionary.story_pos[attachedDictionaryPosIndex].to
          //     )
          //   )

          // skip attachedDictionary with selection text inside
          // if (attachedDictionary && !selectedInAttachedDict) {
          if (attachedDictionary) {
            textArrayPushTmpText()
            const pos = attachedDictionary.story_pos[attachedDictionaryPosIndex]
            textArrayPush(
              storyText.substr(pos.from, pos.to - pos.from),
              pos.from,
              attachedDictionary,
              attachedDictionaryIndex,
            )
            // jump over indices
            charIndex = pos.to - 1
          } else {
            tmpText += storyText[charIndex]
            if (tmpTextPosFrom === null) {
              tmpTextPosFrom = charIndex
            }
          }
        }
      }

      textArrayPushTmpText()
      return textArray
    },
    attachedDictionaryPartOfSpeech: () => attachedDictionary => attachedDictionary.dictionary_item.parts_of_speech
      .filter(partOfSpeech => partOfSpeech.translates.find(translate => translate.id === attachedDictionary.dictionary_translate_id))
      .shift()
      ?.part_of_speech,
  },
  watch: {
    attachedDictionary() {
      if (this.dictionaryNewRepeatCountable) {
        this.calcNewRepeatDictionary()
      }
    },
  },
  mounted() {
    window.addEventListener('keydown', this.keydownHandler)
    window.addEventListener('keyup', this.keyupHandler)
    window.addEventListener('mouseup', this.mouseupHandler)
    this.prepareStoryDictionary()
    this.loadPreviouslyUsedDictionaries()
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.keydownHandler)
    window.removeEventListener('keyup', this.keyupHandler)
    window.removeEventListener('mouseup', this.mouseupHandler)
    if (this.changed && window.confirm('Editor will be closed. Do you want to save changes?')) {
      this.save()
    }
  },
  methods: {
    // EventListener Key
    keydownHandler(event) {
      if (this.dictionaryStoryPosMultiSelectable && event.key === 'Alt') {
        this.selectionMultiple = true
      }
    },
    keyupHandler(event) {
      if (this.dictionaryStoryPosMultiSelectable && event.key === 'Alt') {
        this.selectionMultiple = false
      }
      // Unselect by Escape key
      if (event.key === 'Escape' && this.$refs.dictionarySelector.isVisible()) {
        this.selection = []
        this.$refs.dictionarySelector.hide()
      }
    },
    // EventListener MouseUp
    mouseupHandler() {
      if (!this.editable) {
        return
      }
      const selectedTextData = this.getSelectedTextData()
      if (!selectedTextData) {
        return
      }
      const hasAttachedDictItem = this.attachedDictionary.filter(_dict => {
        let result = false
        if (Array.isArray(_dict.story_pos)) {
          _dict.story_pos.forEach(_pos => {
            if (result === true) {
              return
            }
            for (let i = _pos.from; i <= _pos.to; i += 1) {
              if (selectedTextData.posIndexes.includes(i)) {
                result = true
              }
            }
          })
        }
        return result
      }).length > 0

      if (hasAttachedDictItem) {
        return
      }

      if (!this.selectionMultiple) {
        this.selection = [selectedTextData.pos]
      } else {
        // remove selection overlays
        this.selection = this.selection.filter(pos => {
          for (let i = pos.from + 1; i < pos.to; i += 1) {
            if (selectedTextData.posIndexes.includes(i)) {
              return false
            }
          }
          return true
        })

        // add selection
        this.selection.push(selectedTextData.pos)
      }

      this.dictionarySelectorShow(selectedTextData.selectionString)
    },

    attachDictionaryItemToSelection(dictionaryItem, dictionaryTranslateId, translate, verbTime) {
      const storyPos = cloneDeep(this.selection)

      // remove attached dict at pos
      const indexes = []
      storyPos.forEach(pos => {
        for (let i = pos.from + 1; i < pos.to; i += 1) {
          indexes.push(i)
        }
      })
      this.attachedDictionary = this.attachedDictionary.filter(_ => {
        let result = true
        if (Array.isArray(_.story_pos)) {
          _.story_pos.forEach(pos => {
            if (result === false) {
              return
            }
            for (let i = pos.from; i <= pos.to; i += 1) {
              if (indexes.includes(i)) {
                result = false
                return
              }
            }
          })
        }
        return result
      })

      // update dictionary item
      // this.attachedDictionary.map(_ => {
      //   if (_.dictionary_item.id === dictItem.id) {
      //     this.$set(_, 'dictionary_item', dictItem)
      //   }
      //   return _
      // })

      // attach dictionary item
      const attachedDictItem = {
        dictionary_item: dictionaryItem,
        dictionary_translate_id: dictionaryTranslateId,
        translate,
        verb_time: verbTime,
        audio: null,
        image: null,
        story_pos: storyPos,
        use_in_translate_test: false,
        use_in_grammar_test: false,
        grammar_test_question_pos: null,
        grammar_test_answers: null,
      }
      this.attachedDictionary.push(attachedDictItem)
      this.changed = true
    },

    // dictionarySelector
    dictionarySelectorShow(search) {
      this.$refs.dictionarySelector.show(search)
    },
    dictionarySelector_onSelected(data) {
      this.attachDictionaryItemToSelection(
        data.dictionary,
        data.dictionary_translate_id,
        data.translate,
        data.verb_time,
      )
      this.$refs.dictionarySelector.hide()
      this.selection = []
    },
    dictionarySelector_onClosed() {
      this.selection = []
    },

    showDictionarySelectorItemModal(attachedDictionary) {
      this.$refs.dictionarySelector.hide()
      this.selection = attachedDictionary.story_pos
      this.$refs.dictionarySelectorItemModal.show(
        attachedDictionary.dictionary_item,
        attachedDictionary.dictionary_translate_id,
        attachedDictionary.verb_time,
      )
    },

    // detach from popover
    popoverDetachClick(val) {
      this.$refs[`popover-${val.id}`][0].$emit('close')
      setTimeout(() => this.attachedDictionary.splice(val.attachedDictionaryIndex, 1), 100)
      this.changed = true
    },
    // Cancel changes
    cancel() {
      if (this.changed && window.confirm('Confirm to cancel changes')) {
        this.selection = []
        this.$refs.dictionarySelector.hide()
        this.changed = false
        this.prepareStoryDictionary()
      }
    },
    // Save
    save() {
      this.loading = true
      const data = {
        story_dictionary: this.attachedDictionary.map(_ => ({
          dictionary_translate_id: _.dictionary_translate_id,
          translate: _.translate,
          verb_time: _.verb_time,
          story_pos: _.story_pos,
          use_in_translate_test: _.use_in_translate_test,
          use_in_grammar_test: _.use_in_grammar_test,
          grammar_test_question_pos: _.grammar_test_question_pos,
          grammar_test_answers: _.grammar_test_answers,
        })),
      }

      this.$store.dispatch('lesson/attachDictionary', { id: this.lesson.id, data })
        .then(() => {
          this.selection = []
          this.$refs.dictionarySelector.hide()
          this.changed = false
          this.$emit('lessonUpdated', this.lesson.id)
          this.$toastDefault('Success', 'Save dictionary')
        })
        .catch(error => {
          this.$toastError(get(error, 'response.data.message', 'Error'), 'Save')
        })
        .finally(() => {
          this.loading = false
        })
    },
    calcNewRepeatDictionary() {
      const ids = uniqBy(
        this.attachedDictionary,
        _ => _.dictionary_item.id,
      ).map(_ => _.dictionary_item.id)

      this.dictionaryIsNew = []
      this.dictionaryIsUsedOnce = []
      this.dictionaryIsUsedTwice = []
      this.dictionaryIsUsedMore = []

      ids.forEach(id => {
        const index = this.previouslyUsedDictionaries.findIndex(_ => _.id === id)
        if (index === -1) {
          this.dictionaryIsNew.push(id)
        } else {
          const val = this.previouslyUsedDictionaries[index]
          switch (true) {
            case val.count === 1:
              this.dictionaryIsUsedOnce.push(id)
              break
            case val.count === 2:
              this.dictionaryIsUsedTwice.push(id)
              break
            case val.count > 2:
              this.dictionaryIsUsedMore.push(id)
              break
            default:
              break
          }
        }
      })
    },
    setDictionaryHighlights({
      usedNew = false,
      usedOnce = false,
      usedTwice = false,
      usedMore = false,
    }) {
      this.dictionaryNewHighlight = usedNew
      this.dictionaryUsedOnceHighlight = usedOnce
      this.dictionaryUsedTwiceHighlight = usedTwice
      this.dictionaryUsedMoreHighlight = usedMore
    },
    dictionaryNewHighlightChange() {
      return this.setDictionaryHighlights({ usedNew: !this.dictionaryNewHighlight })
    },
    dictionaryUsedOnceHighlightChange() {
      return this.setDictionaryHighlights({ usedOnce: !this.dictionaryUsedOnceHighlight })
    },
    dictionaryUsedTwiceHighlightChange() {
      return this.setDictionaryHighlights({ usedTwice: !this.dictionaryUsedTwiceHighlight })
    },
    dictionaryUsedMoreHighlightChange() {
      return this.setDictionaryHighlights({ usedMore: !this.dictionaryUsedMoreHighlight })
    },

    // initial methods
    loadPreviouslyUsedDictionaries() {
      this.previouslyUsedDictionaries = []
      const params = {
        lesson_id: this.lesson.id,
      }
      this.$store.dispatch('dictionary/listPreviouslyUsedForLesson', params)
        .then(response => {
          this.previouslyUsedDictionaries = get(response, 'data.result')
        })
        .finally(() => {
          this.calcNewRepeatDictionary()
          this.previouslyUsedDictionariesLoaded = true
        })
    },
    prepareStoryDictionary() {
      this.attachedDictionary = cloneDeep(get(this.lesson, 'story.dictionaries', []))
    },
  },
}
</script>

<style scoped lang="scss">

</style>
