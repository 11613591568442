function secondsToTimeConvert(sec) {
  const minutes = Math.floor(sec / 60)
  const seconds = parseFloat((sec - (minutes * 60)).toFixed(2))
  let result = ''
  result += (minutes < 10) ? `0${minutes}` : minutes
  result += ':'
  result += (seconds < 10) ? `0${seconds}` : seconds
  return result
}

// seconds -> to object (minutes, seconds)
function secondsToMinutesConvert(sec) {
  if (sec === null) {
    return {
      minutes: null,
      seconds: null,
    }
  }
  const minutes = Math.floor(+sec / 60)
  const seconds = +(sec - (minutes * 60)).toFixed(2)
  return {
    minutes,
    seconds,
  }
}

function secondsToAdobePremierTimeConverter(sec, fps = 24) {
  if (sec === null) {
    return ''
  }

  const totalFrames = Math.floor(sec * fps)
  const hours = Math.floor(totalFrames / (fps * 3600))
  const minutes = Math.floor((totalFrames % (fps * 3600)) / (fps * 60))
  const secs = Math.floor((totalFrames % (fps * 60)) / fps)
  const frames = totalFrames % fps

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}:${String(frames).padStart(2, '0')}`
}

function adobePremierTimeToSecondsConverter(adobePremierTime, fps = 24) {
  if (adobePremierTime === null || adobePremierTime === '') {
    return null
  }

  const parts = adobePremierTime.split(':')

  if (parts.length !== 4) {
    return null
  }

  const hours = parseInt(parts[0], 10)
  const minutes = parseInt(parts[1], 10)
  const seconds = parseInt(parts[2], 10)
  const frames = parseInt(parts[3], 10)

  return hours * 3600 + minutes * 60 + seconds + frames / fps
}

export {
  secondsToTimeConvert,
  secondsToMinutesConvert,
  secondsToAdobePremierTimeConverter,
  adobePremierTimeToSecondsConverter,
}
