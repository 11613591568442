<template>
  <div class="d-flex">

    <div
      class="mr-4 mb-2"
      style="width: 300px; min-width: 300px;"
    >
      <h5>TODO</h5>
      <div
        role="alert"
        aria-live="polite"
        aria-atomic="true"
        class="alert alert-warning"
      >
        <div class="alert-body text-center">
          TODO
        </div>
      </div>
    </div>
    <div class="w-100">
      <b-button-group
        size="sm"
        class="mb-1"
      >
        <b-button
          :variant="mode === testTypesEnum.translate ? 'primary' : 'dark'"
          @click="mode = testTypesEnum.translate"
        >
          Translate
        </b-button>
        <b-button
          :variant="mode === testTypesEnum.grammar ? 'primary' : 'dark'"
          @click="mode = testTypesEnum.grammar"
        >
          Grammar
        </b-button>
      </b-button-group>
      <test-translate
        v-if="mode === testTypesEnum.translate"
        :lesson="lesson"
        @lessonUpdated="() => $emit('lessonUpdated')"
      />
      <test-grammar
        v-if="mode === testTypesEnum.grammar"
        :lesson="lesson"
        @lessonUpdated="() => $emit('lessonUpdated')"
      />
    </div>
  </div>
</template>

<script>
import { BButton, BButtonGroup } from 'bootstrap-vue'
import TestTranslate from '@/views/Lesson/Item/Components/TestsTab/TestTranslate/Index.vue'
import TestGrammar from '@/views/Lesson/Item/Components/TestsTab/TestGrammar/Index.vue'
import testTypesEnum from '@/enums/test-types'

export default {
  components: {
    BButton,
    BButtonGroup,
    TestTranslate,
    TestGrammar,
  },
  mixins: [
  ],
  props: {
    lesson: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    mode: testTypesEnum.translate,
  }),
  computed: {
    testTypesEnum() {
      return testTypesEnum
    },
  },
  methods: {
  },
}
</script>

<style scoped>

</style>
