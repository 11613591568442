<template>
  <div>
    <b-button-group
      size="sm"
      class="mb-1"
    >
      <b-button
        v-if="showStory"
        :variant="mode === 'story' ? 'primary' : 'dark'"
        @click="mode = 'story'"
      >
        Story
      </b-button>
      <b-button
        :variant="mode === 'story_dictionary' ? 'primary' : 'dark'"
        @click="mode = 'story_dictionary'"
      >
        Dictionaries
      </b-button>
      <b-button
        :variant="mode === 'video_timecodes' ? 'primary' : 'dark'"
        @click="mode = 'video_timecodes'"
      >
        Timecodes
      </b-button>
    </b-button-group>
    <story
      v-if="mode === 'story'"
      :lesson="lesson"
      @lessonUpdated="() => $emit('lessonUpdated')"
    />
    <story-dictionary
      v-if="mode === 'story_dictionary'"
      :lesson="lesson"
      @lessonUpdated="() => $emit('lessonUpdated')"
    />
    <story-video-timecodes
      v-if="mode === 'video_timecodes'"
      :lesson="lesson"
      :video-type="videoType"
      @lessonUpdated="() => $emit('lessonUpdated')"
    />
  </div>
</template>

<script>
import { BButton, BButtonGroup } from 'bootstrap-vue'
import Story from '@/views/Lesson/Item/Components/VideoTab/TextManager/Story/Index.vue'
import StoryDictionary from '@/views/Lesson/Item/Components/VideoTab/TextManager/StoryDictionary/Index.vue'
import StoryVideoTimecodes from '@/views/Lesson/Item/Components/VideoTab/TextManager/StoryVideoTimecodes/Index.vue'
import videoTypesEnum from '@/enums/video-types'
import upperFirst from 'lodash/upperFirst'

export default {
  components: {
    BButton,
    BButtonGroup,
    Story,
    StoryDictionary,
    StoryVideoTimecodes,
  },
  props: {
    lesson: {
      type: Object,
      required: true,
    },
    videoType: {
      type: String,
      required: true,
    },
    showStory: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    mode: 'text',
  }),
  computed: {
    videoTypesEnum() {
      return videoTypesEnum
    },
    upperFirst() {
      return upperFirst
    },
  },
  created() {
    this.mode = this.showStory ? 'story' : 'story_dictionary'
  },
}
</script>

<style>
</style>
